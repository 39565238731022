@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900');
body {
  background: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
/*-- Transparent navbar ---*/
.transparent {
  border-width: 0px;
  box-shadow: 0px 0px;
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
/* Logo with Text*/
.icasa_header .navbar-brand {
  display: flex;
  align-items: center;
}
.icasa_header .navbar-brand > img {
  padding: 7px 14px;
}
.navbar-brand span {
  font-size: 30px;
  font-weight: bold;
}
.navbar-default .navbar-nav > li > a {
  color: #000 !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.navbar-default .navbar-brand {
  color: #000;
}
.icasa_logo {
  display: inline-block !important;
  height: 39px;
  margin: -13px 8px 0px -3px;
}
.header-content h1 {
  font-weight: bold;
  font-size: 2.14em;
}
.header-content p {
  font-size: 18px;
  line-height: 30px;
}
.header-content p > span {
  font-weight: bold;
}
.header-content {
  margin-top: 109px;
}
.btn.btn-outline {
  border: 2px solid #f55685 !important;
  border-radius: 30px;
  height: 45px;
  line-height: 29px;
  width: 180px;
}
.btn.btn-outline.apply {
  margin-top: 10px;
}
.header-content a.btn.btn-outline {
  color: #f55685;
}
/*--- Jobs blocks css ---*/
div#jobs {
  background: #bec0c5;
  color: #fff;
  padding-bottom: 45px;
}
.caption.job-info h3 {
  font-weight: bold;
}
.caption.job-info p {
  font-size: 14px;
}
.caption.job-info a.btn.btn-outline {
  color: #f55685;
}
.caption.job-info a.btn.btn-outline:hover {
  background: #f55685;
  color: #fff;
}
p.language-us {
  font-size: 12px !important;
  line-height: 0;
}
.vacancy-icon {
  margin-top: 20px;
}
.vacancy-icon .icon {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: auto;
}
.vacancy-icon .icon.backend {
  background: url('../images/backend.png');
  background-size: 175px;
  background-position: center;
}
.vacancy-icon .icon.frontend {
  background: url('../images/frontend.png');
  background-size: 175px;
  background-position: center;
}
.vacancy-icon .icon.fullstack {
  background: url('../images/fullstack.png');
  background-size: 205px;
  background-position: center;
  background-color: #19a8af;
  background-repeat: no-repeat;
}
.social_profile_prog {
  margin-top: 20px;
}
h3.header_title {
  margin: 45px 0px;
  text-transform: uppercase;
  font-weight: bold;
}
/*--- Contacts ---*/
#success-message {
  display: none;
}
#error-message {
  display: none;
}
h4.contact_header {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 2px;
}
span.contact_prefix {
  font-weight: bold;
  font-size: 14px;
}
div#feedback {
  padding-bottom: 45px;
}
header .container {
  margin-bottom: 5px;
}
footer .container {
  border-top: 1px solid #dfe2e5;
  height: 70px;
  line-height: 70px;
}
footer p {
  margin: 0;
}
.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #cccccc;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #dfe3e9;
  border-radius: 3px;
}
input.form-control {
  border-left: 0;
}
input.form-control {
  border: 1px solid #dfe3e9;
  box-shadow: none;
  border-radius: 3px;
  height: 41px !important;
}
.form-control-feedback {
  top: 0;
  right: 15px;
}
textarea.form-control {
  border: 1px solid #dfe3e9;
  box-shadow: none;
  border-radius: 3px;
  resize: vertical;
}
.form-control-feedback {
  width: 44px;
  height: 44px;
  line-height: 44px;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #ed1c24;
}
.has-error .form-control {
  border-color: #ed1c24;
}
.has-error .input-group-addon {
  color: #ed1c24;
  border-color: #ed1c24;
  background-color: transparent;
}
.has-error .form-control-feedback {
  color: #ed1c24;
}
.has-success .input-group-addon {
  color: #1bb934;
  border-color: #1bb934;
  background-color: transparent;
}
.has-success .form-control {
  border-color: #1bb934;
}
.has-success .form-control-feedback {
  color: #1bb934;
}
.btn.btn-outline-contacts {
  border: 2px solid #5788e9 !important;
  border-radius: 30px;
  height: 45px;
  line-height: 29px;
  width: 180px;
  color: #5788e9;
  font-weight: bold;
  background: transparent;
}
.btn.btn-outline-contacts.loading {
  background: url('../images/loading.gif') center center no-repeat;
  background-size: cover;
}
.btn.btn-outline-contacts[disabled] {
  cursor: pointer;
}
.btn.btn-outline-contacts:hover {
  background: #5788e9;
  color: #fff;
}
.btn.btn-outline-contacts:hover.loading {
  background: url('../images/loading.gif') center center no-repeat;
  background-size: cover;
  color: #5788e9;
}
.header-content a.btn.btn-outline:hover {
  background: #f55685;
  color: #fff;
}
.has-error .form-control:focus {
  border-color: #ed1c24;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ed1c24;
}
.has-success .form-control:focus {
  border-color: #1bb934;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.contact-info {
  margin-bottom: 40px;
}
.col-md-10.send_mesage {
  padding-left: 0;
  padding-right: 0;
}
.tehnology_use {
  font-size: 4em;
}
.tehnology_use a {
  text-decoration: none;
}
.tehnology_use a:hover .colored {
  color: #cacaca;
}
